<template>
	<GridOptions v-if="!hideTitle" />
	<!-- <pre>isEmpty: {{ isEmpty }} / user.isMe: {{ user.isMe }}</pre> -->

	<!-- Empty Collection -->
	<div id="gallery-empty" v-if="isEmpty" :class="{ 'left-align': user.isMe }">
		<template v-if="user.isMe">
			<h3 v-if="pageUserStore.isList">This list is still empty.</h3>
			<h3 v-else>
				<template v-if="user.isMe">You haven't</template
				><template v-else>{{ user.name }} hasn't</template> collected any images yet.
			</h3>
			<p>To add images you can:</p>
			<ul>
				<li><router-link :to="{ name: 'Feed' }">Recollect images</router-link> already on Arthur</li>
				<li>
					<a :href="extensionUrl" target="_blank">Import images</a>
					from anywhere on the web using our Chrome extension
				</li>
				<li><router-link :to="{ name: 'Upload' }">Upload images</router-link> from your device</li>
			</ul>
			<br /><br />
			<div class="buttons-wrap">
				<FormButton :level="1" value="Explore" @click="$router.push({ name: 'Home' })" />
				<ButtonExtension />
			</div>
		</template>
		<template v-else-if="pageUserStore.isList">
			<p>This list is still empty.</p>
			<br />
			<FormButton
				:level="1"
				value="Show Collection"
				@click="$router.push({ name: 'User', params: { username: user.username } })"
			/>
		</template>
		<template v-else>{{ possessive(user.name) }} collection is still empty.</template>
		<!-- <a href="/">Home</a> -->
	</div>

	<!-- Collection -->
	<template v-else>
		<TheArtGrid
			:entityType="pageUserStore.isList ? 'list' : 'user'"
			:id="pageUserStore.isList ? pageUserStore.list.id : user.id"
		/>
	</template>
</template>

<script>
// Stores
import { usePageUserStore } from '@/stores/PageUserStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useFeedStore } from '@/stores/FeedStore'

// Components
import TheArtGrid from '@/components/TheArtGrid'
import FormButton from '@/components/FormButton'
import ButtonExtension from '@/components/ButtonExtension'
import GridOptions from '@/components/GridOptions'

// Internal
import { possessive } from '@/use/StringMagic'
import { extensionUrl } from '@/use/Constants'

export default {
	name: 'UserCollection',
	components: {
		TheArtGrid,
		FormButton,
		ButtonExtension,
		GridOptions,
	},
	props: {
		namePath: String,
		user: {
			type: Object,
		},
	},
	setup() {
		const pageUserStore = usePageUserStore()
		const globalStore = useGlobalStore()
		const feedStore = useFeedStore()
		return { possessive, extensionUrl, pageUserStore, globalStore, feedStore }
	},
	computed: {
		isEmpty() {
			if (this.pageUserStore.isList) {
				return this.pageUserStore.list && this.pageUserStore.list.artworkCount === 0
			} else {
				return this.user.artworkCount === 0
			}
		},

		// Only show title when it has relevance
		hideTitle() {
			if (this.isEmpty) {
				if (!this.user.isMe || !this.user.listIds || !this.user.listIds.length) {
					return true
				}
			}
			return false
		},
	},
}
</script>

<style lang="scss" scoped></style>
