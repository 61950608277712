<template>
	<FormButton :level="1" :value="value" icon="extension" @click="openExtension" />
</template>

<script>
import { extensionUrl } from '@/use/Constants'
import FormButton from '@/components/FormButton'
export default {
	name: 'ButtonExtension',
	components: { FormButton },
	props: {
		value: {
			type: String,
			default: 'Install',
		},
	},
	methods: {
		openExtension() {
			window.open(extensionUrl)
		},
	},
}
</script>

<style scoped lang="scss"></style>
