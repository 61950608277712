<template>
	<FormDropdown v-model="listId" :options="ddLists" :title="true" :flip="true" />
</template>

<script>
// Stores
import { usePageUserStore } from '@/stores/PageUserStore'
import { useDialogStore } from '@/stores/DialogStore'
import { useFeedStore } from '@/stores/FeedStore'

// Components
import FormDropdown from '@/components/FormDropdown'

// Internal
import { ddSep } from '@/use/Constants'

export default {
	name: 'UserListDropdown',
	components: {
		FormDropdown,
	},
	setup() {
		const pageUserStore = usePageUserStore()
		const feedStore = useFeedStore()
		return { pageUserStore, feedStore }
	},
	data() {
		return {
			listId: null,
		}
	},
	computed: {
		defaultTitle() {
			return (this.pageUserStore.user.isMe ? 'My ' : '') + 'Collection'
			// if (!this.ddLists) return '@'
			// return this.ddLists.length ? 'Everything' : 'Collection'
		},
		ddLists() {
			if (!this.pageUserStore.user) return []

			const userLists = this.pageUserStore.user.lists || []

			const lists = userLists.map(list => ({
				value: list.id,
				display: list.name + (list.isPrivate ? ' (private)' : ''),
				selected: list.namePath == this.pageUserStore.listNamePath,
			}))

			const options = [
				{
					passiveDisplay: this.defaultTitle,
					display: 'Show all',
					value: 'all',
					selected: !this.pageUserStore.isList,
				},
			]
			if (lists.length) {
				options.push(ddSep)
				options.push(...lists)
			}

			if (this.pageUserStore.isMe) {
				options.push(ddSep)
				options.push({ display: '+ Create list', value: 'create' })
			}
			return options
		},
	},
	watch: {
		// Actions when chnaging the dropdown.
		listId(newValue, oldValue) {
			// Ignore when dropdown is being initialized,
			// or when create option is being reset.
			if (!oldValue || oldValue == 'create') return

			if (newValue == 'create') {
				// Create new list dialog
				console.log('CREATE')
				this.showDialogClusterCreate()
				// nextTick required so the html
				// select menu is properly reset.
				this.$nextTick(() => {
					this.listId = oldValue
				})
			} else if (newValue == 'all') {
				this.feedStore.toggleLoading(true)

				// Go to collection
				this.$router.push({
					name: 'User',
					params: {
						username: this.pageUserStore.user.username,
					},
				})
			} else {
				this.feedStore.toggleLoading(true)

				// Go to list
				this.$router.push({
					name: 'UserList',
					params: {
						username: this.pageUserStore.user.username,
						namePath: this.pageUserStore.user.lists.find(list => list.id == newValue).namePath,
					},
				})
			}
		},

		// When linking between user collections/lists,
		// the created() hook is bypassed to we gotta
		// manually update the dropdown.
		'pageUserStore.list.id'(newValue) {
			this.listId = newValue || 'all'
		},
	},
	created() {
		// // Set user/list
		// const { namePath } = this.$route.params
		// if (namePath) {
		// 	this.pageUserStore.setList(namePath)
		// 	if (this.pageUserStore.list.id) {
		// 		this.listId = this.pageUserStore.list.id
		// 	} else {
		// 		// When you try to load a non-existing collection.
		// 		this.listId = 'all'
		// 	}
		// } else {
		// 	this.listId = 'all'
		// }
	},
	methods: {
		showDialogClusterCreate() {
			const dialogStore = useDialogStore()
			dialogStore.dialogByName('DialogClusterCreate', {
				type: 'list',
			})
		},
	},
}
</script>

<style scoped lang="scss"></style>
