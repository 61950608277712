<template>
	<div
		id="grid-options"
		:class="{ 'comp-no-list-dd': !showUserListDropdown, 'comp-no-nav': tempUserNoNav }"
	>
		<UserListDropdown v-if="showUserListDropdown" />
		<ActionMenu v-if="showMenu" :options="menuOptions" />
	</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'
import { usePageUserStore } from '@/stores/PageUserStore'
import { useFeedStore } from '@/stores/FeedStore'
import { useMeStore } from '@/stores/MeStore'
import { useDialogStore } from '@/stores/DialogStore'

// Components
import UserListDropdown from '@/views/User/UserListDropdown'
import ActionMenu from '@/components/ActionMenu'

// Internal
import flash from '@/use/FlashAlert'
import { handleApiError } from '@/use/Helpers'

export default {
	name: 'GridOptions',
	components: {
		UserListDropdown,
		ActionMenu,
	},
	setup() {
		const feedStore = useFeedStore()
		const meStore = useMeStore()
		const pageUserStore = usePageUserStore()
		const dialogStore = useDialogStore()
		return { feedStore, meStore, pageUserStore, dialogStore }
	},
	computed: {
		theRoute() {
			return this.$gl.lockedRoute || this.$route
		},
		showUserListDropdown() {
			if (this.theRoute.name == 'User' || this.theRoute.name == 'UserList') {
				const hasLists = !!(this.pageUserStore.user.listIds && this.pageUserStore.user.listIds.length)
				const isMe = this.pageUserStore.user.isMe
				return hasLists || isMe
			}
			return false
		},
		showMenu() {
			const isMe = this.pageUserStore.user.isMe
			return isMe && this.theRoute.name == 'UserList'
		},
		menuOptions() {
			if (this.theRoute.name == 'UserList') {
				return [
					{
						value: 'edit',
						display: 'Edit list',
						onSelect: () => {
							this.pageUserStore.toggleListEditMode(true)
						},
					},
					{
						value: 'delete',
						display: 'Delete list',
						onSelect: this.showDialogDelete,
					},
					{
						value: 'make-room',
						display: 'Make this a room',
						onSelect: this.showDialogMakeRoom,
					},
				]
			} else {
				return []
			}
		},
		tempUserNoNav() {
			if (this.theRoute.name == 'User' || this.theRoute.name == 'UserList') {
				const noRooms = !(this.pageUserStore.user.roomIds && this.pageUserStore.user.roomIds.length)
				return noRooms
			}
			return false
		},
	},
	methods: {
		showDialogDelete() {
			const apiStore = useApiStore()
			const clusterApi = apiStore.loadApi('cluster')
			this.dialogStore.confirmCritical(async () => {
				const { status, statusText, data } = await clusterApi.delete(this.pageUserStore.list.id)
				if (status == 200) {
					flash(
						`Your list <em>'${this.pageUserStore.list.name}'</em> has been successfully deleted.`,
						{
							title: 'List Deleted',
							type: 'success',
							html: true,
						}
					)
				} else {
					handleApiError({ status, data, statusText, action: 'deleting this list' })
				}
				this.$router.push({ name: 'User', params: { username: this.$myUsername } })
				this.meStore.loadMyClusters()
			})
		},

		showDialogMakeRoom() {
			const apiStore = useApiStore()
			const clusterApi = apiStore.loadApi('cluster')
			this.dialogStore.confirmCritical(
				async () => {
					const { status, statusText, data } = await clusterApi.listToRoom(
						this.pageUserStore.list.id
					)
					if (status == 200) {
						this.$router.push({ name: 'Room', params: { id: data.id } })
						flash(
							`Your room <em>'${this.pageUserStore.list.name}'</em> has been successfully created.`,
							{
								title: 'Room Created',
								type: 'success',
								html: true,
								// button: `Open room`,
								// buttonRoute,
							}
						)
					} else {
						handleApiError({ status, data, statusText, action: 'creating your room' })
					}
					this.meStore.loadMyClusters()
				},
				{
					title: 'Turn List into Room',
					actionKey: 'create room',
					params: {
						message:
							"<p>You're about to turn this list into a room. This cannot be undone. Are you sure you want to continue?</p>",
						html: true,
						warning: 'Alright!',
					},
				}
			)
		},
	},
}
</script>

<style scoped lang="scss">
#grid-options {
	// background: pink;
	height: 0.5rem;
	display: flex;
	align-items: center;
	margin: 0.2rem calc(var(--pp) - 0.1rem) 0.1rem calc(var(--pp) - 0.1rem);
}

// Temporary: This will go as soon as we add additional grid options.
// Reduce height when no dropdown and no nav is shown.
// http://localhost:8080/ben-clark
#grid-options.comp-no-list-dd {
	margin: 0;
	height: 0;
}
// http://localhost:8080/suzan-hamer
#grid-options.comp-no-nav {
	// height: 0;
	margin-top: 0.4rem;
}
// http://localhost:8080/alex-benzer
#grid-options.comp-no-list-dd.comp-no-nav {
	margin: 0;
	height: 0.2rem;
}

// Actions
// #grid-options:deep() .act-menu {
// 	// background: orange;
// }

// Dropdown
#grid-options > .wrap:first-child {
	margin-top: 0.1rem;
	flex: 1 0;
	height: 0.5rem;
	// background: lightblue;
}
#grid-options > .wrap:first-child:deep() select,
#grid-options > .wrap:first-child:deep() .dsp {
	height: 0.5rem;
}

// Header when no dropdown is available
#grid-options h1 {
	margin: 0;
}

// @media only screen and (max-width: $bp-grid-pad) {
// 	#grid-options {
// 		margin: 0 3px -0.4rem 3px;
// 	}
// }

@media only screen and (max-width: $mobile) {
}
</style>
